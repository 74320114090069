import { useTranslation } from "gatsby-plugin-react-i18next";
import * as humps from "humps";
import React, { FormEvent, useState } from "react";
import Helmet from "react-helmet";
import "twin.macro";

import { ContactUsForm } from "../components/ContactUsForm";
import { Container } from "../components/Container";
import { Layout } from "../components/Layout/Layout";
import { ContactForm } from "../interfaces/contact_form";
import { useContactAPIUrl } from "../utils/common";

const initForm: ContactForm = {
  firstName: "",
  lastName: "",
  email: "",
  message: "",
  contactReason: "3",
  captcha: "",
  captchaInput: "",
  agreement: false,
  lang: "en",
  phoneNumber: ""
};

const ContactUs: React.FC = ({}) => {
  const { i18n, t } = useTranslation();
  const [form, setForm] = useState<ContactForm>(initForm);
  const [hasError, setHasError] = useState<boolean>(false);
  const [successful, setSuccessful] = useState<boolean>(false);
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    form.lang = i18n.language;
    const request = new Request(useContactAPIUrl(), {
      method: "POST",
      body: JSON.stringify({ data: { type: "contacts", attributes: humps.decamelizeKeys(form) } }),
      headers: { "Content-Type": "application/vnd.api+json" }
    });

    fetch(request)
      .then(response => {
        if (!response.ok) {
          setSuccessful(false);
          throw Error(response.statusText);
        } else if (response.ok) {
          setHasError(false);
          setSuccessful(true);
        }
      })
      .then(() => setForm(initForm))
      .catch(() => setHasError(true));
  };

  const status = { error: hasError, success: successful };

  return (
    <Layout>
      <Helmet title={t("contactUs.header")} />
      <Container tw="pt-16 pb-32">
        <article tw="mx-auto text-center max-w-prose">
          <h1 tw="font-black text-4xl">{t("contactUs.header")}</h1>
          <p tw="mt-8 mx-auto text-2xl">{t("contactUs.hereForYou")}</p>
          <p tw="mt-8 mb-16 mx-auto text-2xl">{t("contactUs.subheader")}</p>
        </article>
        <ContactUsForm form={form} setForm={setForm} handleSubmit={handleSubmit} status={status} />
      </Container>
    </Layout>
  );
};

export default ContactUs;
